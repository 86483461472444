import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Table } from "react-bootstrap";
import { MdCancel, MdHeight } from "react-icons/md"; // Import the cancel icon
import { cancleJobByID } from "../redux/slices/postFilterSlice";
import { getHistory } from "../redux/slices/postFilterSlice";
export default function ActiveJobs() {
  const dispatch = useDispatch();
  const { jobs } = useSelector((state) => state.postFilter);

  useEffect(() => {
  dispatch(getHistory);
  },[jobs]);

  return (
    <table
      className="w-100 mt-3 ml-3"
      style={{
        textAlign: "center",
        justifyContent: "center",
        alignContent: "space-evenly",
      }}
    >
      <thead
        className="border-bottom border-primary text-light"
        style={{ height: "4rem" }}
      >
        <tr>
          <td style={{ width: "16.66%" }}>id</td>
          <td style={{ width: "16.66%" }}>creation date</td>
          <td style={{ width: "16.66%" }}>finished</td>
          <td style={{ width: "16.66%" }}>Total Amount</td>
          <td style={{ width: "16.66%" }}></td>
        </tr>
      </thead>

      <tbody className=" bg-dark text-light">
        {jobs && jobs.length > 0 ? (
          jobs.map((item, index) => (
            <tr  onCompositionStart={()=>{alert("test")}} key={index}>
              <td style={{ width: "16.66%" }}>{item.id}</td>
              <td style={{ width: "16.66%" }}>{item.creation_date}</td>
              <td style={{ width: "16.66%" }}>{item?.finished_count}</td>
              <td style={{ width: "16.66%" }}>{item?.luminaire_count}</td>

              <td
                style={{
                  width: "16.66%",
                  color: "red",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                <MdCancel
                  size={20}
                  onClick={() => dispatch(cancleJobByID(item.id))}
                />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6" style={{ textAlign: "center" }}>
              No active jobs
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
