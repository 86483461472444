import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addLuminaires } from "../redux/slices/filterSlice";
const LDTInput = () => {
  const dispatch = useDispatch();
  const inputRef = useRef();

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const fileReadPromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const base64String = event.target.result.split(",")[1];
          resolve({
            name: file.name,
            base64: base64String,
          });
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    Promise.all(fileReadPromises)
      .then((data) => {
        if (!(data[0].name.toLowerCase().endsWith('.ldt'))){
        alert("not a valid .ldt file")
        return
        }else{ 
        dispatch(addLuminaires(data));
      }
      })
      .catch((error) => {
        console.error("Error reading files:", error);
        alert("An error occurred while reading files.");
      });
    e.target.label = null;
    e.target.result = null;
    e.target.value = null;
  };

  //{ldt.length}
  return (
    <React.Fragment>
      <Button
        className="w-100"
        variant="primary"
        onClick={() => {
          inputRef.current.click();
        }}
      >
        Choose LDT file(s)
      </Button>
      <input
        type="file"
        style={{ display: "none" }}
        className="form-control bg-dark text-light border-0"
        id="inputGroupFile"
        multiple
        ref={inputRef}
        onChange={handleFileChange}
      ></input>
    </React.Fragment>
  );
};

export default LDTInput;
