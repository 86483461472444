import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../utility/API";

export const getActiveJobs = createAsyncThunk(
  "postFilter/getActiveJobs",
  async () => {
    try {
      var data = await API.fetch("active_jobs", "GET");
      return data;
    } catch (error) {
      throw new Error(error);
    } finally {
    }
  }
);

export const getHistory = createAsyncThunk(
  "postFilter/getHistory",
  async () => {
    try {
      var data = await API.fetch("history", "GET");
      data.files.sort(
        (a, b) => new Date(a.creation_date) - new Date(b.creation_date)
      );
      return data;
    } catch (error) {
      throw new Error(error);
    } finally {
    }
  }
);
export const cancleJobByID = createAsyncThunk(
  "postfilter/cancleJob",
  async (id) => {
    try {
      return await API.fetch(
        "cancel_active_job",
        "POST",
        JSON.stringify({
          id: id,
        })
      );
    } catch (error) {
      throw new Error(error);
    } finally{
      try {
      
        var data = await API.fetch("active_jobs", "GET");
        return data;
      } catch (error) {
        throw new Error(error);
      }
    }
  }
);
export const historyDeleteFileById = createAsyncThunk(
  "postFilter/historyDeleteFileById",
  async (file_id) => {
    try {
      // Making the DELETE request to the backend route
      await API.fetch(`history/${file_id}`, "DELETE");
      return file_id; // Return the file ID to remove it from the state
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getJob = createAsyncThunk("postFilter/getJob", async (id) => {
  try {
    return await API.fetch(
      "result",
      "POST",
      JSON.stringify({
        id: id,
      })
    );
  } catch (error) {
    throw new Error(error);
  } finally {
  }
});
export const addToCachedHistory = createAsyncThunk(
  "postFilter/addToCachedHistory",
  async (id, { getState, dispatch }) => {
    const { cachedHistory, ableToAddCache } = getState().postFilter;
    var contains = false;
    for (let i = 0; i < cachedHistory.length; i++) {
      if (cachedHistory[i].id.includes(id)) {
        contains = true;
        return;
      }
    }
    if (contains) return;
    let result;
    dispatch(setAbleToAddCache(false));
    try {
      result = await API.fetch(
        "result",
        "POST",
        JSON.stringify({
          id: id,
        })
      );
    } catch (error) {
      dispatch(setAbleToAddCache(true));
      throw new Error(error);
    } finally {
      const today = new Date();
      const dd = today.getDate().toString().padStart(2, "0"); // Day of the month
      const mm = (today.getMonth() + 1).toString().padStart(2, "0"); // Month (0-based index)
      const yyyy = today.getFullYear(); // Year
      const hh = today.getHours().toString().padStart(2, "0"); // Hours
      const min = today.getMinutes().toString().padStart(2, "0"); // Minutes

      const creation_date = `${yyyy}-${mm}-${dd} ${hh}:${min}`;
      const data = {
        id: id,
        creation_date: creation_date,
        result: result,
      };
      dispatch(setAbleToAddCache(true));
      return data;
    }
  }
);
export const saveCachedHistory = createAsyncThunk(
  "postFilter/saveCachedHistory",
  async (_, { getState }) => {
    const state = getState();
    try {
      localStorage.setItem(
        "cachedHistory",
        JSON.stringify(state.postFilter.cachedHistory)
      );
    } catch (error) {
      throw new Error(error);
    } finally {
    }
  }
);
export const loadCachedHistory = createAsyncThunk(
  "postFilter/loadCachedHistory",
  async () => {
    let result;
    try {
      result = JSON.parse(localStorage.getItem("cachedHistory"));
    } catch (error) {
      throw new Error(error);
    } finally {
      return result;
    }
  }
);

const postFilterSlice = createSlice({
  name: "postFilter",
  initialState: {
    tab: "passed",
    luminaires: null,
    ableToAddCache: true,
    jobs: [],
    history: [],
    cachedHistory: JSON.parse(localStorage.getItem("cachedHistory")) || [],
    job: null,
    loadingJob: false,
  },
  reducers: {
    setAbleToAddCache(state, action) {
      state.ableToAddCache = action.payload;
    },
    updateFilter: (state, action) => {
      state.job = { ...state.job, filter: action.payload };
    },
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    updateProgress: (state, action) => {
      const { id, creation_date, luminaire_count, finished_count } =
        action.payload;
      const index = state.jobs.findIndex((job) => job.id === id);
      if (index !== -1) {
        if (finished_count === luminaire_count)
          state.jobs = state.jobs.filter((job) => job.id !== id);
        else
          state.jobs[index] = {
            ...state.jobs[index],
            finished: finished_count === luminaire_count,
            luminaire_count: luminaire_count,
            finished_count: finished_count,
          };
      } else {
        state.jobs.push({
          id: id,
          finished: finished_count === luminaire_count,
          creation_date: creation_date,
          luminaire_count: luminaire_count,
          finished_count: finished_count,
        });
      }
    },
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setLuminaires: (state, action) => {
      state.luminaires = action.payload;
    },
    setJob: (state, action) => {
      state.job = action.payload;
    },
    removeFromCachedHistory: (state, action) => {
      console.log("remove Beginn");
      const data = state.cachedHistory;
      const filterdata = data.filter((item) => item.id !== action.payload.id);
      state.cachedHistory = filterdata;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToCachedHistory.fulfilled, (state, action) => {
        state.cachedHistory = [...state.cachedHistory, action.payload];
      })
      .addCase(loadCachedHistory.fulfilled, (state, action) => {
        state.cachedHistory = action.payload;
      })
      .addCase(historyDeleteFileById.fulfilled, (state, action) => {
        if (Array.isArray(state.history.files)) {
          state.history.files = state.history.files.filter(
            (file) => file.name !== action.payload
          );
        } else {
          console.error("history.files is not an array");
        }
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        if (state.cachedHistory.length < 1) {
          state.history = action.payload;
          return;
        }
        const names = state.cachedHistory.map((item) => item.id);
        const historyWithoutCache = action.payload.files.filter(
          (item) => !names.includes(item.name)
        );
        const tmp = {};
        tmp.files = historyWithoutCache;
        state.history = tmp;
      })
      .addCase(getHistory.rejected, (state, action) => {
        console.log(action.error);
      })
      .addCase(getHistory.pending, (state) => {})
      .addCase(getJob.pending, (state) => {
        state.loadingJob = true;
      })
      .addCase(getJob.fulfilled, (state, action) => {
        state.job = action.payload;
        state.loadingJob = false;
      })
      .addCase(getJob.rejected, (state, action) => {
        console.log(action.error);
        state.loadingJob = false;
      })
      .addCase(getActiveJobs.rejected, (state, action) => {
        console.log(action.error);
      })
      .addCase(getActiveJobs.fulfilled, (state, action) => {
        state.jobs = action.payload;
      })
      .addCase(cancleJobByID.fulfilled, (state, action) => {
        state.jobs = action.payload;
      });
  },
});

export const {
  updateFilter,
  setHistory,
  setData,
  setLuminaires,
  setTab,
  setJob,
  updateProgress,
  removeFromCachedHistory,
  setAbleToAddCache
} = postFilterSlice.actions;

export default postFilterSlice.reducer;
