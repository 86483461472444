import React, { useEffect } from "react";
import { setRecadFile } from "../redux/slices/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import defaultFile from "../square_new.recad";
import { API } from "../utility/API";
const RecadInput = () => {
  const { recad_file } = useSelector((state) => state.filter);

  const dispatch = useDispatch();

  const fetchFileAsBase64 = async () => {
    const response = await fetch(defaultFile);
    const blob = await response.blob();

    // Convert Blob to Base64
    const reader = new FileReader();
    var base64String;
    reader.onloadend = () => {
      base64String = reader.result.split(",")[1]; // Extract Base64
      dispatch(
        setRecadFile({
          name: "defaultRecad.recad",
          base64: base64String,
        })
      );
    };
    reader.readAsDataURL(blob);
  };

  useEffect(() => {
    fetchFileAsBase64();
  }, []);

  const handleFileChange = async (e) => {
    e = e;
    const file = e.target.files[0];
    const reader = new FileReader();
    const recadBevor = recad_file;
    const isLdt = file.name.includes(".recad");
    if(!isLdt){
      fetchFileAsBase64();
      alert("Not a valid ReCAD")
      e.target.value="";
    }
    // Read the file as Base64 string

    reader.onload = (event) => {
      const base64String = event.target.result.split(",")[1]; // Extract Base64 part
      dispatch(
        setRecadFile({
          name: file.name,
          base64: base64String,
        })
      );
    };

    if (file) {
      reader.readAsDataURL(file); // This triggers the `onload` event
    }
    var data;
    try {
      data = await API.fetch(
        "check_ReCad",
        "POST",
        JSON.stringify({
          recad: recad_file,
        })
      );
    } catch (error) {
      console.log(error.message);
    }
    if (!data) {
      fetchFileAsBase64();
      alert("ReCAD file wasn't valid");
      e.target.value="";
    }
  };

  return (
    <React.Fragment>
      <div data-bs-theme="dark" className="input-group custom-file-button">
        <label
          className="input-group-text"
          style={{
            width: "30%",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          ReCAD File :
        </label>
        <input
          type="file"
          className="form-control"
          id="inputGroupFile"
          onChange={handleFileChange}
        />
      </div>
    </React.Fragment>
  );
};

export default RecadInput;
