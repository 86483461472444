import React from "react";
import { Tab, Tabs, Container, Row, Col, Button } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import Card from "react-bootstrap/Card";
import { RiArrowGoBackLine } from "react-icons/ri";

import Passed from "./PostFilter/Passed";
import Failed from "./PostFilter/Failed";
import Convergence from "./PostFilter/Convergence";

import { useSelector, useDispatch } from "react-redux";
import { setTab, setJob } from "../redux/slices/postFilterSlice";

export default function PostFilter() {
  const dispatch = useDispatch();
  const job = useSelector((state) => state.postFilter.job);
  const tab = useSelector((state) => state.postFilter.tab);

  return (
    <Container fluid>
      <Row
        style={{
          fontSize: "1em",
          height: "100%",
          background: "#00000047",
          justifyContent: "space-around",
          overflowY: "scroll",
        }}
      >
        <Col sm={11}>
          <Card data-bs-theme="dark" className="m-3">
            <Card.Header>
              <Col>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    dispatch(setJob(null));
                  }}
                  style={{ marginRight: "0.7rem" }}
                >
                  <RiArrowGoBackLine /> Jobs & History
                </Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <blockquote className="blockquote mb-0">
                <p>
                  Selected job:{" "}
                  <Badge>
                  <span
                    className=""
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {job.id}
                  </span>
                  </Badge>
                  <Badge className="ml-3 m-1" bg="secondary">{job.prefilterstats.vendor}</Badge>
                  <Badge className="m-1" bg="secondary">{job.prefilterstats.color_temperature}</Badge>
                  <Badge className="m-1" bg="secondary">{job.prefilterstats.optimisation}</Badge>
                  <Badge className="m-1" bg="secondary">{job.prefilterstats.norm[0]+" "+job.prefilterstats.norm[1]}</Badge>
                </p>
              </blockquote>
            </Card.Body>
          </Card>
          <Tabs
            data-bs-theme="dark"
            onSelect={(k) => dispatch(setTab(k))}
            variant="tabs"
            activeKey={tab}
            className="m-3 text-primary"
            justify
          >
            <Tab variant="" className="w-100" eventKey="passed" title="Success">
              <Passed />
            </Tab>

            <Tab eventKey="failed" title="Failed">
              <Failed />
            </Tab>

            <Tab className="w-100" eventKey="convergence" title="Convergence">
              <Convergence className="w-100" />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}
