import React from "react";
import { MdDeleteForever } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { removeLuminaire, resetLuminaires } from "../redux/slices/filterSlice";
import { GrPowerReset } from "react-icons/gr";

const LDTList = () => {
  const dispatch = useDispatch();

  const luminaires = useSelector((state) => state.filter.luminaires);
  return (
    <div className="w-100" style={{maxHeight:'100%'}}>
      {luminaires && luminaires.length > 0 && (
        <div
          className="m-3 h-100 bg-dark bg-gradient rounded"
          style={{ opacity: "0.7" }}
        >
   
            <GrPowerReset
              className="text-primary m-3"
              size={"1.3rem"}
              onClick={() => {
                dispatch(resetLuminaires());
              }}
              style={{ float: "right" }}
            />
      
          <div className="w-100">
              <div className="text-light m-3" style={{ float: "left" }}>
                Total Selection : {luminaires.length}
              </div>
            </div>
          <div
            className="m-3 text-light scrollcustom"
            style={{
              height: "50%",
              width:"80%",
              overflowY: "scroll",
              textAlign: "center",
            }}
          >
          
            <div className="m-3">
              {luminaires.map((file, index) => (
                <div
                  key={index}
                  onClick={() => dispatch(removeLuminaire(file.name))}
                >
                  {file.name}
                  <MdDeleteForever
                    className="m-1"

                    style={{float:'right' }}
                  ></MdDeleteForever>
                </div>
              ))}
            </div>
            <hr
              style={{ bottom: "0px", float: "inline-end", position: "sticky" }}
            ></hr>
          </div>
        </div>
      )}
    </div>
  );
};

export default LDTList;
