import { useEffect } from "react";
import { InputGroup, Form, Row, Col, Button } from "react-bootstrap";
import NormInput from "../components/NormInput";
import "../form.css";
import { resetFilter, setMountingType } from "../redux/slices/filterSlice";

import {
  updateColorTemperatureList,
  updateVendorList,
} from "../redux/slices/filterListSlice";
import { useSelector, useDispatch } from "react-redux";
import RecadInput from "../components/RecadInput";
import ws from "../utility/websocket";
import Optimize from "../components/Optimize";
import { setTab } from "../redux/slices/appState";
import { resetLuminaires } from "../redux/slices/filterSlice";

export default function Prefilter() {
  const filter = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const {tab} = useSelector((state)=>state.appState)

  useEffect(() => {
    return () => {
      dispatch(resetLuminaires())
    }
  }, [tab])

  useEffect(() => {
    dispatch(updateColorTemperatureList());
    dispatch(updateVendorList());
  }, [dispatch, filter.norm, filter.mounting_type]);

  useEffect(() => {
    dispatch(updateVendorList());
  }, [dispatch, filter.color_temperature]);

  return (
    <Row className="mt-3">
      <Col
        sm={11}
        className="m-3"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <RecadInput />
      </Col>
      <Col sm={11} className="m-3">
        <InputGroup data-bs-theme="dark">
          <InputGroup.Text
            style={{
              width: "30%",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            Mounting Type :
          </InputGroup.Text>
          <Form.Select
            className="select"
            value={filter?.mounting_type ?? ""}
            onChange={(e) => {
              dispatch(setMountingType(e.target.value));
            }}
          >
            <option value=""></option>
            <option value="recessed">Recessed</option>
            <option value="surface_mounted">Surface mounted</option>
            <option value="pendel">Pendel</option>
          </Form.Select>
        </InputGroup>
      </Col>
      <Col sm={11} className="m-3">
        <Optimize />
      </Col>

      <Col sm={"11"} className="m-3">
        <NormInput />
      </Col>

      <Col sm={11} className="m-3 mt-0">
        <Button
          className="mt-3 w-100"
          disabled={!filter.validated}
          onClick={async (event) => {
            ws.send(JSON.stringify({ type: "start", data: filter }));
            dispatch(setTab("jobs"));
            dispatch(resetFilter());
          }}
        >
          Start Automizer
        </Button>
      </Col>
    </Row>
  );
}
